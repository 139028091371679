@import 'assets/css/bootstrap-dashboard.css';
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/latest/css/font-awesome.min.css");

html{
  overflow-y: overlay;
}
html, body {

  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  background-color: #f5f5f5;
}
/* make keyframes that tell the start state and the end state of our object */
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade-in {
    opacity: 0; /* make things invisible upon start */
    -webkit-animation: fadeIn ease-in 1; /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
    -moz-animation: fadeIn ease-in 1;
    animation: fadeIn ease-in 1;
    -webkit-animation-fill-mode: forwards; /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    animation-duration: 0.5s;
}
@-webkit-keyframes widthOut {
  from {
    width: 330px;
  }

  to {
    width: 500px;
  }
}

@-moz-keyframes widthOut {
  from {
    width: 330px;
  }

  to {
    width: 500px;
  }
}
@media screen and (max-width: 1279px) {
  body {
    overflow-x: hidden!important;
  }
}
@keyframes widthOut {
  from {
    width: 330px;
  }

  to {
    width: 500px;
  }
}


@media screen and (min-width: 1279px){
  .allargare {
    width: 330px;
    -webkit-animation: widthOut ease-in 1; /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
    -moz-animation: widthOut ease-in 1;
    animation: widthOut ease-in 1;
    -webkit-animation-fill-mode: forwards; /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    animation-duration: 0.5s;
  }
  .rimpicciolire {
    width: 500px;
    -webkit-animation: widthIn ease-in 1; /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
    -moz-animation: widthIn ease-in 1;
    animation: widthIn ease-in 1;
    -webkit-animation-fill-mode: forwards; /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-duration: 0.5s;
    -moz-animation-duration: 0.5s;
    animation-duration: 0.5s;
  }
}
@keyframes widthIn {
  from {
    width: 500px;
  }

  to {
    width: 330px;
  }
}

@keyframes widthIn {
  from {
    width: 500px;
  }

  to {
    width: 330px;
  }
}

@keyframes widthIn {
  from {
    width: 500px;
  }

  to {
    width: 330px;
  }
}


.table{
  margin-bottom: 4rem;
}


.m-0 {
  margin: 0;
}


